.Gallery-Section-Tabs-con {
    margin: 30px 0px;
}

.Tabs-container {
    /* margin: 60px 0px 50px 0px; */
    /* background-color: var(---color01); */
    padding: 20px 0px;
    color: var(---color01);
    background: url("../../Images/backgroun\ theam.avif");
    background-size: 100% 100%;
    box-shadow: 0 0 3px gray;
}

.ButtonG {
    border: none;
    color: white;
    font-weight: 600;
    font-family: var(---Font02);
    letter-spacing: 1.5px;
}

.ButtonG u {
    text-decoration: none;
    font-family: var(---font01);
    font-size: 14px;

}

.btn.active {
    background-color: #DC9D7E !important;
    color: white    ;
}

.btn-outline {
    background-color: var(---color01) !important;
}

.btn:hover {
    background-color: var(---color01) !important;
    color: white;
}

.btn:hover .ButtonG {
    color: white!important;
}

@media (max-width:700px) {
    #tabsAll {
        display: flex;
        flex-direction: column;
    }
}