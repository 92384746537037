.About-Section-Mission-Main-con {
    padding: 10vh 0px;
    background-image: url("../../Images/Pages/About/services-bg.webp");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.About-Section-Mission-con {
    /* border: 1px solid red; */
}

.About-Section-Deatils-con {
    text-align: left;
}

.About-Section-Deatils-con h2 {
    font-size: 89px;
    line-height: 1em;
    white-space: normal;
    margin: 0px 0px 20px 0px;
    color: #0000002E;
}

.About-Section-Deatils-con h3 {
    font-family: var(---font01);
    margin: 0px 0px 20px 0px;
}

.About-Section-Deatils-con p {
    font-family: var(---font01);
    font-size: 14px;
}