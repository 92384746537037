.Gallery-Section-1 {
    background:url("../Images/Pages/Gallery/Banner.jpg");
    -webkit-background-size: cover;
    height: 80vh;
}

.Gallery-Section-con {
    align-items: center;
    display: flex;
    height: 80vh;
}

.Gallery-Section-Text {
    margin: 0 0 0 30px;
}

.Gallery-Section-Text h1 {
    color: #fff;
    font-family: var(---font01);
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}

.Gallery-Section-NavPage {
    align-items: center;
    display: flex;
    font-family: var(---font01);
}

.Gallery-Section-NavPage span {
    color: #fff;
    font-family: var(---font01);
}

.Gallery-Section-NavPage span svg {
    font-size: 17px;
    margin: 0 4px 5px 0;
}

.Gallery-Section-NavPage h3 svg {
    color: #fff;
}

.Gallery-Section-NavPage h2 {
    color: #fff;
    font-family: var(---font01);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}


@media(max-width:430px) {
    .Gallery-Section-1 {
        -webkit-background-size: cover;
        height: 60vh;
    }

    .Gallery-Section-con {
        align-items: center;
        display: flex;
        height: 70vh;
    }
    .Gallery-Section-Text h1 {
        color: #fff;
        font-family: var(---font01);
        font-size: 20px;
    }
}