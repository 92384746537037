.ArchitectureSec-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px 0px 0px;
}

.Home-Architecture-Sec-main-con {
    /* border: 1px solid red; */
}

.Home-Architecture-Sec-Image-main-con {
    /* border: 1px solid blue; */
}

.Home-Architecture-Se-Image1-con {
    /* border: 1px solid hotpink; */
    height: 100%;
    display: flex;
    justify-content: right;
    align-items: end;
}

.Home-Architecture-Sec-Image1 {
    /* border: 1px solid saddlebrown; */
    height: auto;
    width: 100%;
}

.Home-Architecture-Sec-Image1 img {
    height: 100%;
    width: 100%;
}

.Home-Architecture-Se-Image2-con {
    /* border: 1px solid sandybrown; */

}

.Home-Architecture-Sec-Image2 {
    /* border: 1px solid blue; */
    width: 100%;
}

.Home-Architecture-Sec-Image2 img {
    height: 100%;
    width: 100%;
}

.Home-Architecture-Sec-Image2-main-con {
    /* border: 1px solid red; */
}

.Home-Architecture-Sec-Image3-main-con {
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-Architecture-Sec-Image3 {
    /* border: 1px solid blue; */
    height: 40vh;
    width: 55vh;
    margin-top: 20px;
}

.Home-Architecture-Sec-Image3 img {
    height: 100%;
    width: 100%;
}

/* /Home-Architecture-Sec-Details-con-main */

.Home-Architecture-Sec-Details-con-main {
    /* border: 1px solid blue; */
}

.Home-Architecture-Sec-heading {}

.Home-Architecture-Sec-heading h2 {
    font-family: var(---font01);
    font-size: 14px;
    letter-spacing: 1px;
    padding: 15px 0px;
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
}

.Home-Architecture-Sec-Details-con {
    /* border: 1px solid blue; */
    margin: 40px 0px 0px 0px;
}

.Home-Architecture-Sec-Info-con {
    /* border: 1px solid red; */
}

.Home-Architecture-Sec-Info-icon {
    /* border: 1px solid blue; */
}

.Home-Architecture-Sec-Info-icon svg {}

.Home-Section-Architecture-Details {
    text-align: left;
}

.Home-Section-Architecture-Details h3 {
    font-family: var(---font01);
    font-size: 17px;
    font-weight: 600;
    margin: 0px 0px 8px 0px;
    color: black;
}

.Home-Section-Architecture-Details p {
    font-size: 13px;
    font-family: var(---font01);
}

@media(max-width:768px) {

    .Home-Architecture-Sec-Image3 {
        /* border: 1px solid blue; */
        height: auto;
        width: 40vh;
    }

    .Home-Architecture-Sec-Image3-main-con {
        justify-content: left;
    }

    .Home-Architecture-Sec-Image1 {
        /* border: 1px solid saddlebrown; */
        height: auto;
    }

    .Home-Architecture-Sec-Image2 {
        /* border: 1px solid blue; */
        height: auto;
        width: 90%;
    }

}

@media(max-width:576px){
    .Home-Architecture-Se-Image2-con{
margin-top: 30px;
    }
}

@media(max-width:430px) {
    .Home-Architecture-Sec-Image3-main-con {
        display: none;
    }
}