.Error_page {
  padding: 5vh;
}

.banner404 {
  height: 40vh;
  background-color: #DC9D7E;
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner404 h1 {
  margin-top: 40px;
  color: white;
}


.error-details {
  height: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorlogo {
  width: 100%;
  height: 100%;
}

.errorlogo img {
  width: 40%;
  height: 90%;
}

.ErrorDesc {
  padding: 20px 0px;
}

.ErrorDesc h2 {
  font-size: 60px;
  color: #302043;
  margin-bottom: 20px;
}

.ErrorDesc span {
  color: #DC9D7E;
}

.ErrorDesc p {
  color: #808080;
  margin-bottom: 20px;
}

.ErrorDesc a {
  padding: 15px 40px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 3;
  color: white;
  background-color: black;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  transition: all 0.3s ease;
}

.ErrorDesc a:hover {
  box-shadow: 0px 10px 10px -10px rgba(54, 24, 79, 0.5);
  transform: translateY(5px);
  background: #DC9D7E;
  color: white;
}

.ErrorRight {
  display: flex;
  justify-content: center;
  align-items: center;
}

.errorImg {
  width: 100%;
  height: 100%;
}

.errorImg img {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
}

@media(max-width:600px) {
  .ErrorDesc h2 {
    font-size: 30px;
    color: #302043;
  }

  .ErrorRight {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px;
  }

  .ErrorDesc a {
    padding: 7px 20px;
  }
}