.Service-Section-1 {
    background: url("../Images/Pages/Service/77\ \(2\).jpg");
    -webkit-background-size: cover;
    height: 80vh;
}

.Service-Section-con {
    align-items: center;
    display: flex;
    height: 80vh;
}

.Service-Section-Text {
    margin: 0 0 0 30px;
}

.Service-Section-Text h1 {
    color: #fff;
    font-family: var(---font01);
    font-size: 30px;
    font-weight: 500;
    text-align: left;
    text-transform: capitalize;
}

.Service-Section-NavPage {
    align-items: center;
    display: flex;
    font-family: var(---font01);
}

.Service-Section-NavPage span {
    color: #fff;
    font-family: var(---font01);
}

.Service-Section-NavPage span svg {
    font-size: 17px;
    margin: 0 4px 5px 0;
}

.Service-Section-NavPage h3 svg {
    color: #fff;
}

.Service-Section-NavPage h2 {
    color: #fff;
    font-family: var(---font01);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

/* Service Section 2  */

.Service-Section2-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.Service-Section-2-about {}

.Service-Section-2-Details-con {
    /* width: 80%; */
}

.Service-Section-2-Details-con p {
    text-align: left;
    font-family: var(---font01);
    margin: 30px 0px 0px 0px;
}

.Service-section-2-heading h2 {
    padding: 15px 10px;
    text-align: left;
    font-size: 30px;
    font-weight: 300;
}

.Service-Section-2-details-image-con {
    /* border: 1px solid blue; */
    display: flex;
    justify-content: end;
}

.Service-Section-2-details-image {
    /* border: 1px solid red; */
    height: auto;
    width: 100%;
}

.Service-Section-2-details-image img {
    height: 100%;
    width: 100%;
}


/* ////////////// Section 3 //////////////////////// */


.Service-Section-3-con {
    padding: 10vh 0px;
    background: url("../Images/backgroun\ theam.avif");
    background-size: 100% 100%;
    background-attachment: fixed;
}

/* // */

.Service-Section-3-card-con {
    /* border: 1px solid blue; */
}

.Service-Section-3-Card-Body {
    /* border: 1px solid red; */
    /* margin: 20px; */
    box-shadow: 0 3px 22px rgba(0, 0, 0, .06);
}

.Service-Section-3-Card-Body a {
    text-decoration: none;
    color: black;
}

.Service-Section3-Card-body-con {
    position: relative;
    /* border: 1px solid blue; */
    height: auto;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
}

.Service-Section3-Card-body-con img {
    height: 100%;
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: transform .4s;
    transform: scale(1.01);
}

.Service-Section-3-Card-text-con {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: whitesmoke;
}

.Service-Section-3-Card-text-con h2 {
    font-family: var(---font01);
    font-size: 22px;
    padding: 15px;
    margin: 0px;
    font-weight: 300;
    color: black;
}

.Service-Section-3-overlay-con {
    /* border: 1px solid blue; */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Service-Section-3-Overlay-icon {
    padding: 20px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    transition: opacity .3s, transform 1s cubic-bezier(.23, 1, .32, 1);
    opacity: 0;
}

.Service-Section-3-Overlay-icon svg {
    color: var(---color01);
    font-size: 30px;
}


.Service-Section-3-Card-Body:hover .Service-Section-3-Overlay-icon {
    opacity: 1;
    transition: all .5s ease-in-out;
}

.Service-Section-3-Card-Body:hover .Service-Section3-Card-body-con img {
    transform: scale(1.08);
}


/* ////////////////// Section 4 //////////////////////// */

.section-Service-4-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.section-Service-4-form-con {
    /* border: 1px solid red; */
    padding: 30px 0px;
}

.Section-Service-4-form-Image-con {
    /* border: 1px solid pink; */
    height: auto;
}

.Section-Service-4-form-Image-con img {
    height: 100%;
    width: 100%;
}

.Service-Section-4-form-con-main {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Service-Section-4-form-con {
    /* transform: translate(-100px, 0px); */
    padding: 30px;
    box-shadow: 3px 0px 33px 0px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
}

.Service-Section-4-form-head {}

.Service-Section-4-form-head h3 {
    position: relative;
    padding: 0px 0px 10px 0px;
    margin-bottom: 20px;
    text-align: left;
    font-family: var(---font01);
}

.Service-form-head::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
    width: 30%;
    background-color: var(---color01);
}

.Service-Section-4-form-head p {
    font-family: var(---font01);
    color: #505050;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: left;
}

.Service-Section-form-inputs-con {
    margin-top: 30px;
}

:where(.css-dev-only-do-not-override-42nv3w).ant-btn {
    width: 80%;
    background-color: var(---color01) !important;
    height: 40px;
}

.background-text-color {
    color: white;
    font-family: var(---font01);
}

.Gallery-Section-tabs-head-con h2 {
    font-family: var(---font01);
}

.Service-Section-2-details-below-para {
    margin-top: 30px;
}

.Service-Section-2-details-below-para p {
    text-align: left;
    font-family: var(---font01);
}

@media(max-width:992px) {
    .Service-Section-2-Details-con {
        width: 100%;
    }

    .Service-Section3-Card-body-con {
        position: relative;
        /* border: 1px solid blue; */
        height: 45vh;
    }

    .Service-Section-3-Card-Body {
        /* border: 1px solid red; */
        margin: 0px;
    }

    .Service-Section-3-card-con {
        /* border: 1px solid blue; */
        margin: 30px 0px 0px 0px;
    }
}

@media(max-width:768px) {
    .Service-Section-3-Card-Body {
        /* border: 1px solid red; */
        margin: 30px 0px 0px 0px;
    }

    .Service-Section-3-Card-Body {
        /* border: 1px solid red; */
        margin: 20px;
        box-shadow: 0 3px 22px rgba(0, 0, 0, .06);
        width: min-content;
    }

    .Service-Section3-Card-body-con {
        width: 285px;
        height: auto;
    }

    .Service-Section-2-details-image-con {
        justify-content: start;
        margin-top: 30px;
    }
}

@media(max-width:578px) {
    .Section-Service-4-form-Image-con {
        height: auto;
        margin: 0px 20px;
    }

    .Service-Section-4-form-con-main {
        margin-top: 30px;
    }
}


@media(max-width:430px) {
    .Service-Section-1 {
        background: url("../Images/Pages/Service/res.jpg");
        -webkit-background-size: cover;
        height: 60vh;
    }

    .Service-Section-con {
        align-items: center;
        display: flex;
        height: 70vh;
    }

    .Service-Section-Text h1 {
        color: #fff;
        font-family: var(---font01);
        font-size: 20px;
    }

    .Service-Section-2-details-image {
        height: auto;
        width: 100%;
    }

    .Service-Section3-Card-body-con {
        width: 100%;
        height: 40vh;
    }

    .Service-Section-3-Card-Body {
        width: auto;
    }

}