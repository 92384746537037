.ServiceDetails-Section-1 {
    background: linear-gradient(90deg, #0006, #0006), url("../Images/Pages/ServiceDetails/banner.webp");
    -webkit-background-size: cover;
    height: 80vh;
}

.ServiceDetails-Section-con {
    align-items: center;
    display: flex;
    height: 80vh;
}

.ServiceDetails-Section-Text {
    margin: 0 0 0 30px;
}

.ServiceDetails-Section-Text h1 {
    color: #fff;
    font-family: var(---font01);
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
    text-transform: capitalize;
}

.ServiceDetails-Section-NavPage {
    align-items: center;
    display: flex;
    font-family: var(---font01);
}

.ServiceDetails-Section-NavPage span {
    color: #fff;
    font-family: var(---font01);
}

.ServiceDetails-Section-NavPage span svg {
    font-size: 17px;
    margin: 0 4px 5px 0;
}

.ServiceDetails-Section-NavPage h3 svg {
    color: #fff;
}

.ServiceDetails-Section-NavPage h2 {
    color: #fff;
    font-family: var(---font01);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
}

/* ////////////////////////////////////////////////////////////////////// */

.service-details-con {
    position: sticky;
    top: 0;
}

.service-details-section-2-con {
    /* border: 1px solid blue;/ */
    padding: 20px 0px;
}

.Service-details-con-heading {
    margin-bottom: 30px;
}

.Service-details-con-heading h2 {
    font-size: 30px;
    font-family: var(---font01);
    text-align: left;
}

.service-details-section-2-service-name {
    /* border: 1px solid red; */
}

.service-details-item-body-con {
    /* border: 1px solid green; */
    margin-bottom: 20px;
    text-align: left;
}

.service-details-item-body-con a{
    text-decoration: none;
    color: black;
}

.service-details-item-body-con:hover {}

.service-details-item-body-con h3 {
    font-family: var(---font01);
    text-transform: capitalize;
    font-size: 15px;
    margin: 0px;
    text-align: left;
    position: relative;
    padding-left: 30px;
}

.service-taxt-style::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    height: 1px;
    width: 20px;
    background-color: var(---color01);

}

/* //////////////////////////////////////////// */

.section-Service-details-info-con {
    /* border: 1px solid blue; */
}

.section-Service-details-info-con-intro {
    text-align: left;
}


.section-Service-details-info-con-intro h3 {
    font-family: var(---font01);
    font-size: 16px;
    color: gray;
    letter-spacing: 1px;
    text-transform: capitalize;
}

.section-Service-details-info-con-intro h4 {
    font-family: var(---font01);
    font-size: 25px;
}

.section-Service-details-info-con-intro p {
    font-size: 16px;
    font-family: var(---color01);
}

.section-service-details-info-points-con {
    /* border: 1px solid red; */
    padding: 30px 0px;
}

.section-service-details-points-image-con {
    /* border: 1px solid blue; */
    height: auto;
}

.section-service-details-points-image-con img {
    height: 100%;
    width: 100%;
}

.section-service-details-points-body {
    text-align: left;
}

.section-service-details-points-card {
    /* border: 1px solid blue; */
    margin-bottom: 20px;
    text-align: left;
}

.section-service-details-points-card h5 {
    font-family: var(---font01);
    font-size: 23px;
    font-weight: 500;
    position: relative;
    padding-bottom: 6px;
}

.section-service-details-points-card p {
    font-family: var(---font01);
    font-size: 15px;
}

.Section-Service-details-points-style::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    height: 1px;
    width: 70px;
    background-color: var(---color01);
}

.section-service-details-points-body-para {
    text-align: left;
    font-family: var(---font01);
    font-size: 15px;
    margin: 30px 0px;
}

.Service-Details-Card-Image-con {
    /* border: 1px solid red; */
    height: auto;
}

.Service-Details-Card-Image-con img {
    height: 100%;
    width: 100%;
}


/* ///////////////////////////////////////////////////////////////// */

.service-details-2-our-works-con {
    padding: 15vh 0px;
    background-color: black;
}

.service-details-2-our-work {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-detals-2-our-work-details {}


.service-detals-2-our-work-details h2 {
    font-family: var(---font01);
    font-size: 40px;
    margin: 0px 0px 30px 0px;
    color: white;

}

.service-detals-2-our-work-details-para {
    display: flex;
    justify-content: center;
    text-align: center;
}

.service-detals-2-our-work-details-para p {
    width: 80%;
    font-family: var(---font01);
    margin-bottom: 40px;
    color: white;
}

.service-detals-2-our-work-details-btn {}

.service-detals-2-our-work-details-btn button {
    border: none;
    background-color: var(---color01);
    color: white;
    padding: 6px 15px;
    border-radius: 3px;
    box-shadow: 0 0 5px var(---color01);
}

.section-service-details-points-body-image .swiper-wrapper {
    padding: 0 0 40px;
}

.swiper-pagination-bullet-active {
    background-color: var(---color01) !important;
}

.Section-Service-Details-Points-2-con{
    /* border: 1px solid blue; */
    margin: 20px 0px;
}

@media(max-width:768px){
    .section-service-details-points-body{
        margin-top: 30px;
    }
}