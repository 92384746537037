.Home-section-Banner-con {
    /* border: 1px solid red; */
}


.Home-Banner-Image-con {
    /* border: 1px solid blue; */
    height: 100vh;
    background-image: url("../Images/Pages/Home/28.avif");
    background-size: cover;
    display: flex;
    align-items: center;
}

.Home-Banner-Text-Con {
    /* border: 1px solid blue; */
    width: 30%;
    margin: 0px 0px 0px 50px;
    text-align: left;
}

.Home-Banner-Text-details {
    /* border: 1px solid red; */
}


.Home-Banner-Text-details h2 {
    font-family: var(---font01);
    color: var(---color01);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    /* margin: 0px; */
}

.Home-Banner-Text-details h1 {
    font-family: var(---font01);
    color: black;
}

.Home-Banner-Text-details p {
    font-family: var(---font01);
    font-size: 14px;
    color: black;
}

.Home-Banner-btn-con {
    /* border: 1px solid blue; */
}

.Home-Banner-btn-con a {}

.Home-Banner-btn-con button {
    border: none;
    background-color: var(---color01);
    color: white;
    padding: 6px 15px;
    border-radius: 3px;
    box-shadow: 0 0 5px var(---color01);
}

.Home-Gallery-btn-con{
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home-Gallery-btn{
    border:1px solid gray;
    padding: 7px 30px;
    display: inline-block;
    border-radius: 10px;
    background-color: #4f746e;
    color: white;
}

@media(max-width:768px) {
    .Home-Banner-Text-details h1 {
        font-size: 25px;
    }

    .Home-Banner-Text-Con {
        /* border: 1px solid blue; */
        width: 60%;
    }
}

@media(max-width:576px) {
    .Home-Banner-Image-con {
        /* border: 1px solid blue; */
        height: 80vh;
    }

    .Home-Banner-Text-details p {
        font-family: var(---font01);
        font-size: 12px;
    }

    .Home-Banner-btn-con button {
        font-size: 13px;
    }

    .welcome-area .single-item {
        padding: 30px 30px 35px;
    }

}

@media(max-width:430px) {
    .Home-Architecture-Sec-Image3 {
        /* border: 1px solid blue; */
        height: 50vh;
        width: 40vh;
        transform: translate(0px, -100px);
    }

    .Home-Banner-Text-Con {
        width: 100%;
    }

    .Home-Banner-Image-con {
        /* border: 1px solid blue; */
        height: 60vh;
    }

    .Home-Banner-Text-details {
        margin-top: 40px;
    }

    .Home-Banner-Text-Con {
        margin: 0px 0px 0px 15px;
        text-align: left;
    }
}