.card {
    /* border: 1px solid red; */
    height: fit-content;
    position: relative;
}

.card img {
    height: 100%;
    width: 100%;
    border-radius: 5px;
}

.card:hover .Card-text {
    background: rgba(51, 51, 51, 0.5);
}

.card:hover .Card-text h2 {
    display: block;
    color: whitesmoke;
}



.Card-text {
    /* border: 1px solid blue; */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 5px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    -webkit-transition: background-color 1s ease-out;
    -moz-transition: background-color 1s ease-out;
    -o-transition: background-color 1s ease-out;
    transition: background-color 1s ease-out;
}

.Card-text h2 {
    font-family: var(---StyleFont);
    display: none;
}

.ant-image {
    height: auto;
}

.ant-image img {
    height: 100% !important;
    width: 100% !important;
}

@media(max-width:768px) {
    .card {
        /* border: 1px solid red; */
        height: auto;
        position: relative;
    }

    .ant-image {
        height: auto;
    }

}