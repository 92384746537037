.Footer-Main-Section {
    /* border: 1px solid blue; */
    position: sticky;
    /* font-size: 25px; */
}

.Footer-Section-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
    background: url("../../Images/backgroun\ theam.avif");
    background-size: 100% 100%;
}

.Footer-Section-Details {
    /* border: 1px solid blueviolet; */
}

.footer-section-logo-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-section-logo-main {
    /* border: 1px solid blue; */
    height: 70px;
    width: 170px;
}

.footer-section-logo-main img {
    height: 100%;
    width: 100%;
    /* -webkit-filter: invert(100%); */
    /* Safari/Chrome */
    /* filter: invert(100%); */
}

/* menu section  */

.footer-section-footer-menu-con {
    /* border: 1px solid blue; */
    margin: 50px 0px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-section-footer-menu {
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
/* @media (min-width:700px){
    .footer-section-footer-menu-con{
        background-color: red;
    }
} */

.footer-section-footer-menu a {
    text-decoration: none;
    color: black;
}

.footer-section-footer-menu a h3 {
    font-family: var(---font01);
    font-size: 14px;
    margin: 0px;
    font-weight: 600;
    color: white;
}


/* address */

.footer-section-footer-address-con {
    margin: 50px 0px 0px 0px;
    text-align: center;
}

.footer-section-footer-address-con p {
    font-family: var(---font01);
    font-size: 16px;
    margin: 0px;
    color: white;
}

/* contact  */

.footer-section-footer-contact-con {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0px 0px 0px;
    /* border: 1px solid blue; */
}

.footer-section-footer-contact {
    /* border: 1px solid red; */
    width: 35%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-section-footer-contact a {
    text-decoration: none;
}

.footer-section-footer-contact a h3 {
    font-family: var(---font01);
    font-size: 16px;
    margin: 0px;
    color: white;
}

/* social media icons  */

.footer-section-footer-social-media-main-con {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px 0px 0px;
}

.footer-section-footer-social-media-main {
    /* border: 1px solid blue; */
    width: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.footer-section-footer-social-media-main a {}

.footer-section-footer-social-media-main a svg {
    color: white;
    font-size: 20px;
}

.Footer-copy-right-section-con {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0f0f0f;
    padding: 5vh 0px;
}

.Footer-copy-right-section {}

.Footer-copy-right-section p {
    margin: 0px;
    color: white;
}

.Footer-copy-right-section p span {
    color: #bbbbbb;
}

.Footer-copy-right-section p a {
    text-decoration: none;
    color: var(---color01);
}

.footer-name{
    color: var(---color01) !important;
}
@media (max-width: 700px){
    .footer-section-footer-menu{
        display: flex;
        flex-direction: column;
        gap: 10px;
        flex-wrap: wrap;
        font-size: 19px;
    }
    #footer-contact a h3{
        display: flex;
        flex-direction: column;
        gap: 1px;
    }
    .footer-section-footer-contact{
        display: none;

    }
    #footer-contact1{
        display: flex;
        flex-direction: column;
        gap: 1px;
    
    }
    #footer-contact1 a h3{
    }
}


@media (min-width:700px){
    .slidercros{
        display:none !important ;
    }
}

@media(max-width:992px) {
    .footer-section-footer-contact {
        /* border: 1px solid red; */
        width: 60%;
    }

    .navbar-nav {
        display: none;
    }
    .footer-section-footer-menu{
        width: 100%;
    }
}

/* @media(max-width:429px){
    .footer-section-footer-menu{
        width: 500px;
        background-color: orangered;
        margin-left: 10px;
    }
    .footer-section-footer-menu a{
        display: flex;
        padding-left: 10px; 
        width: 400px;
        background-color: red;
        margin-right: 5px;

    }
    .footer-section-footer-menu a h3{
    padding: 5px;
}
} */

@media (max-width: 768px) {
    .footer-section-footer-contact {
        /* border: 1px solid red; */
        width: 85%;
    }

    .footer-section-footer-social-media-main a svg {
        color: white;
        font-size: 25px;
    }

    .footer-section-footer-social-media-main {
        /* border: 1px solid blue; */
        width: 40%;
    }
}

@media (max-width:509px) and (min-width:500px){
    #span{
    top: 23px;
    position: relative;
    right: 149px;
    }
    #span1{
        color: white;
        position: relative;
        left: 105px;
    }
    .p{
       position: relative;
       top: 17px;
    }
    .Footer-copy-right-section-con{
    flex-wrap: wrap-reverse;

    }
}
@media (min-width:430px) and (max-width:413px){
    #span{
        position: relative;
        top: 55px;
        
    }
}

@media(max-width:430px) {
    .footer-section-footer-menu {
        width: 100%;
    }

    .footer-section-footer-contact {
        /* border: 1px solid red; */
        width: 100%;
    }
}