.Home-Process-Section {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.Home-Section-Process-main-con {
    /* border: 1px solid red; */

}

.Home-Section-Process-head-con {
    /* border: 1px solid green; */
    text-align: left;
    width: 80%;
    position: sticky;
    top: calc(30px + 99px, 0px);
}

.Home-Section-Process-head-con h2 {
    position: relative;
    font-family: var(---font01);
    padding: 0px 0px 10px 0px;
}

.Head-Style-Service::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    background-color: var(---color01);
    width: 70px;
}

.Home-Section-Process-head-con p {
    font-family: var(---font01);
}

.Home-Section-Prosses-Details-con {
    /* border: 1px solid blue; */
    text-align: left;
}

.Home-Section-Prosses-number {
    /* border: 1px solid red; */
}

.Home-Section-Prosses-number h2 {
    font-size: 160px;
    color: #f0f0f0;
}

.Home-Section-Prosses-details-info {
    margin: -90px 0px 0px 0px;
}

.Home-Section-Prosses-details-info h3 {
    font-size: 30px;
    font-family: var(---font01);
    width: 50%;
    margin: 0px 0px 20px 0px;
}

.Home-Section-Prosses-details-info p {}

@media(max-width:430px) {
    .Home-Section-Process-head-con {
        width: 100%;
    }
}