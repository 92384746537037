/* team section con  */


.About-Section-2-head {}


.About-Section-2-head-title {
    text-align: left;
    margin: 0px 0px 40px 0px;
}

.About-Section-2-head-title h2 {
    font-family: var(---font01);
}

.About-Section-2-head-title p {
    font-family: var(---font01);
}

.about-section-2-con {
    /* border: 1px solid blue; */
    padding: 10vh 0px;
}

.about-section-2-card-con {
    margin: 0px 0px 30px 0px;
    /* border: 1px solid red; */
    overflow: hidden;
}

.about-section-2-card-body {
    /* border: 1px solid green; */
    height: auto;
    width: 100%;
    position: relative;
    /* display: table; */
    table-layout: fixed;
    -webkit-transform: scale(1.0001);
    -ms-transform: scale(1.0001);
    transform: scale(1.0001);
}

.about-section-2-card-body img {
    height: 100%;
    width: 100%;
}

/* card overlay  */

.about-section-2-card-details {
    text-align: left;
    padding: 25px 15px 25px;
    text-align: left;
    bottom: 0;
    position: absolute;
    width: 100%;
    display: block;
    background-color: #fff;
    height: auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: bottom;
    transition: all .4s ease;
    transform: translate3d(0, 100%, 0);
}

.about-section-2-card-details h3 {
    font-weight: 500;
    color: #1b1b1b;
    letter-spacing: .02em;
    font-family: var(---font01);
    text-transform: uppercase;
    font-size: 18px;
}

.about-section-2-card-details p {
    font-family: var(---font01);
    font-weight: 300;
}

.about-section-card-social-media-con {
    /* border: 1px solid blue; */
}

.about-section-card-social-media-con a {
    /* border: 1px solid red; */
    margin: 0px 5px;
}

.about-section-card-social-media-con a svg {
    font-size: 20px;
    color: #cfcfcf;
}

.about-section-2-card-con:hover .about-section-2-card-details {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

@media(max-width:992px) {
    .about-section-2-card-body {
        /* border: 1px solid green; */
        height: 40vh;
    }

    .about-section-2-card-details h3 {
        font-size: 16px;
    }

    .about-section-2-card-details {
        text-align: left;
        padding: 25px 25px 25px;
    }
}

@media(max-width:768px) {
    .about-section-2-card-body {
        /* border: 1px solid green; */
        height: 50vh;
        width: 100%;
    }
}

@media(max-width:575px) {
    .about-section-2-card-body {
        /* border: 1px solid green; */
        height: auto;
        width: 100%;
    }
}

@media(max-width:430px) {
    .about-section-2-card-body {
        /* border: 1px solid green; */
        height: auto;
        width: 100%;
    }
}